import { Box, Theme, useTheme } from '@mui/material'
import React, { FunctionComponent, ReactNode } from 'react'

const useStyles = (theme: Theme) => ({
  subHeader: {
    display: 'flex',
    justifyContent: 'center',
    height: '110px',
    padding: '0 35px 0',
    [theme.breakpoints.up('md')]: {
      height: '160px',
    },
  },
})

type Props = {
  children: ReactNode
}

const SubHeader: FunctionComponent<Props> = ({ children }: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return <Box sx={classes.subHeader}>{children}</Box>
}

export default SubHeader
