import React, { FunctionComponent } from 'react'
import { EnrollmentStep, IndustryTypes } from '@/state/types'
import { body2FontSize, fontSecondary, stepperBorderColor, stepperColor } from '@/theme'
import { useSelector } from 'react-redux'
import { selectEnrollmentState, selectPlanState, selectActivationState } from '@/state/selectors'
import { isNoAccount } from '@/utils'
import { Box, Hidden, Step, StepConnector, StepLabel, Stepper, Theme, Typography, useTheme } from '@mui/material'

const isCXIQ = process.env.THEME_NAME === 'CreditScoreIQ' || process.env.THEME_NAME === 'CreditBuilderIQ'

const useStyles = (theme: Theme) => ({
  stepperRoot: {
    padding: 0,
    background: 'transparent',
    maxWidth: '1100px',
    margin: '0 auto',
    '& .MuiStepIcon-root': {
      color: '#9DA3A8',
      width: 28,
      height: 28,
      overflow: 'initial',
    },
    '& .MuiStep-root': {
      color: '#666',
      '& .Mui-completed': {
        fontWeight: 400,
        '& .MuiStepIcon-root': {
          color: '#65B920',
        },
      },
      '& .MuiStepLabel-label': {
        color: '#666',
      },
    },
    '& .MuiStepConnector-root': {
      left: 'calc(-50% + 14px)',
      right: 'calc(50% + 14px)',
      top: '14px',
    },
    '& *': {
      fontFamily: fontSecondary,
    },
    '& .MuiStepIcon-text': {
      fontFamily: fontSecondary,
      fontWeight: 600,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '320px',
    },
  },
  stepperActive: {
    opacity: 1,
    '& .MuiStepLabel-root': {
      '& span.Mui-active': {
        color: theme.palette.common.black,
        fontWeight: isCXIQ ? 500 : 700,
      },
      '& .MuiStepIcon-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiStepLabel-iconContainer div': {
        background: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  stepperLabel: {
    color: stepperColor,
  },
  root: {
    width: '100%',
    padding: 0,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  dot: {
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    border: stepperBorderColor,
    marginTop: '5px',
  },
  dotFilled: {
    background: stepperColor,
  },
  stepName: {
    textAlign: 'center',
    color: theme.palette.common.black,
    fontWeight: isCXIQ ? 500 : 700,
    fontFamily: fontSecondary,
    fontSize: theme.typography.body2.fontSize || body2FontSize,
    margin: '-10px 0 10px 0',
    paddingTop: '8px',
  },
  stepperAlternativeLabel: {
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
    top: '14px',
  },
  stepperLineHorizontal: {
    borderTopWidth: '1px',
    borderColor: '#D6D6D6',
  },
})

type Props = {
  activeStep: EnrollmentStep
}

const ProgressStepper: FunctionComponent<Props> = ({ activeStep }: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const { details } = useSelector(selectPlanState)
  const { isProveWorkflow } = useSelector(selectEnrollmentState)
  const { details: activationDetails } = useSelector(selectActivationState)

  let steps =
    !details || details?.isPlanAuthRequired
      ? ['Create Your Account', 'Identity Information', 'Billing Information']
      : ['Create Your Account', 'Complete Enrollment']

  const isNoAccountPlan = isNoAccount(details)
  if (isNoAccountPlan && steps.length == 3) {
    steps.pop()
  }

  if (isProveWorkflow) {
    steps = !isNoAccountPlan
      ? ['Quick Sign Up', 'Confirm Your Information', 'Billing Information']
      : ['Quick Sign Up', 'Confirm Your Information']
  }

  if (activationDetails) {
    const stepone =
      details?.industryType === IndustryTypes.EmployeeBenefit ? 'Create Your Account' : 'Confirm Your Account'
    steps = [stepone]

    if (details?.isPlanAuthRequired) {
      const isAutoEnroll = activationDetails.memberInfo.orderSource === 'AUTOENROLL'
      const steptwo =
        details?.industryType === IndustryTypes.EmployeeBenefit ? 'Identity Information' : 'Complete Enrollment'
      steps.push(isAutoEnroll ? 'Complete Activation' : steptwo)
    }
  }

  return (
    <Box sx={classes.root}>
      <Hidden mdUp>
        <Typography variant="body1" sx={classes.stepName}>
          {`${activeStep + 1}. ${steps[activeStep]}`}
        </Typography>
      </Hidden>
      <Stepper
        sx={classes.stepperRoot}
        activeStep={activeStep}
        alternativeLabel
        connector={
          <StepConnector
            sx={{
              lineHorizontal: classes.stepperLineHorizontal,
              alternativeLabel: classes.stepperAlternativeLabel,
            }}
          />
        }
      >
        {steps.map((label, index) => (
          <Step key={label} sx={index === activeStep ? classes.stepperActive : undefined}>
            <StepLabel>
              <Hidden mdDown>
                <span>{`${index + 1}. ${label}`}</span>
              </Hidden>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default ProgressStepper
