import React, { FunctionComponent } from 'react'
import { fontSecondary, Theme } from '@/theme'
import { Images } from '@/constants'
import { Box, useTheme } from '@mui/material'

const isCSIQ = process.env.THEME_NAME === 'CreditScoreIQ'

const useStyles = (theme: Theme) => ({
  footerContainer: {
    padding: '20px 6px',
    margin: '42px 0 12px 0',
  },
  footerText: {
    textAlign: 'center',
    padding: '5px',
    color: theme.palette.text.secondary,
    fontSize: '14px',
    fontFamily: theme.typography.body1.fontFamily || fontSecondary,
    fontWeight: 400,
    paddingLeft: '14px',
  },
  csiqLogosGrouped: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '200px',
    margin: '0 auto',
    padding: '12px 0',
    [theme.breakpoints.down('sm')]: {
      padding: '3px 0',
    },
  },
  logosGrouped: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginLeft: '10px',
  },
  logoLock: {
    marginBottom: '12px',
    fontSize: isCSIQ ? '16px' : '14px',
    fontWeight: isCSIQ ? 500 : 600,
    fontFamily: theme.typography.body1.fontFamily,
    color: theme.palette.text.secondary,
    '& img': {
      marginRight: isCSIQ ? '16px' : '8px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: isCSIQ ? '15px' : '14px',
    },
  },
  trustwaveContainer: {
    display: 'flex',
  },
  spacer: {
    flexGrow: 10,
  },
})

const Footer: FunctionComponent = () => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const currentYear = new Date().getFullYear()
  const footerText = process.env.FOOTERTEXT?.replace('{year}', currentYear.toString()) || ''

  return (
    <>
      <Box sx={classes.spacer} />
      <Box sx={classes.footerContainer} id={'global-footer'}>
        <Box sx={[classes.logosGrouped, classes.logoLock]}>
          <img width="21" height="26" src={Images.LockPrivacySecurityLogo} alt={Images.LockPrivacySecurityLogo} />
          Privacy & Security Protected
        </Box>
        <Box sx={classes.csiqLogosGrouped}>
          <img height="58" src={Images.BetterBusinessBureauLogo} alt={Images.BetterBusinessBureauLogo} />
          <img height="50" src={Images.TrustwaveSeal} alt={Images.TrustwaveSeal} />
        </Box>
        <Box sx={classes.footerText}>
          <p dangerouslySetInnerHTML={{ __html: footerText }}></p>
        </Box>
      </Box>
    </>
  )
}

export default Footer
