import { InitialValues } from '@/components/FormAccountInfo/initialValues'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectEnrollmentState, selectActivationState, selectResidentDataState } from '@/state/selectors'
import useAccountInfoParams from './useAccountInfoParams'

interface Args {
  setTouched: (touched: Record<string, boolean>, shouldValidate?: boolean | undefined) => void
  setValues: (values: InitialValues, shouldValidate?: boolean | undefined) => void
}

const useGlobalAccountState = ({ setValues, setTouched }: Args): void => {
  const { account } = useSelector(selectEnrollmentState)
  const { details: activationDetails } = useSelector(selectActivationState)
  const { details: residentData } = useSelector(selectResidentDataState)
  const { queryParams } = useAccountInfoParams()

  useEffect(() => {
    const {
      firstName: firstNameFromState,
      middleInitial: middleInitialFromState,
      lastName: lastNameFromState,
      email: emailFromState,
      currentStreet: currentStreetFromState,
      currentCity: currentCityFromState,
      currentState: currentStateFromState,
      currentZip: currentZipFromState,
      previousStreet: previousStreetFromState,
      previousCity: previousCityFromState,
      previousState: previousStateFromState,
      previousZip: previousZipFromState,
      familyInvitationEmail: familyInvitationEmailFromState,
    } = account

    const {
      firstName: firstNameFromQuery,
      lastName: lastNameFromQuery,
      email: emailFromQuery,
      currentStreet: currentStreetFromQuery,
      currentState: currentStateFromQuery,
      currentZip: currentZipFromQuery,
      currentCity: currentCityFromQuery,
      previousStreet: previousStreetFromQuery,
      previousState: previousStateFromQuery,
      previousZip: previousZipFromQuery,
      previousCity: previousCityFromQuery,
      familyInvitationEmail: familyInvitationEmailFromQuery,
    } = queryParams

    const {
      memberInfo: {
        firstName: firstNameFromActivation,
        lastName: lastNameFromActivation,
        email: emailFromActivation,
        address: currentStreetFromActivation,
        state: currentStateFromActivation,
        zip: currentZipFromActivation,
        city: currentCityFromcAtivation,
        orderSource: activationSource,
      },
    } = activationDetails || { memberInfo: {} }

    const {
      residentId,
      firstName: firstNameFromResident,
      lastName: lastNameFromResident,
      email: emailFromResident,
      address: streetFromResident,
      state: stateFromResident,
      zip: zipFromResident,
      city: cityFromResident,
    } = residentData || {}

    const firstName = firstNameFromState || firstNameFromQuery || firstNameFromActivation || firstNameFromResident || ''
    const middleInitial = middleInitialFromState
    const lastName = lastNameFromState || lastNameFromQuery || lastNameFromActivation || lastNameFromResident || ''
    const email = emailFromState || emailFromQuery || emailFromActivation || emailFromResident || ''
    const currentStreet =
      currentStreetFromState || currentStreetFromQuery || currentStreetFromActivation || streetFromResident || ''
    const currentCity =
      currentCityFromState || currentCityFromQuery || currentCityFromcAtivation || cityFromResident || ''
    const currentState =
      currentStateFromState || currentStateFromQuery || currentStateFromActivation || stateFromResident || ''
    const currentZip = currentZipFromState || currentZipFromQuery || currentZipFromActivation || zipFromResident || ''
    const previousStreet = previousStreetFromState || previousStreetFromQuery || ''
    const previousCity = previousCityFromState || previousCityFromQuery || ''
    const previousState = previousStateFromState || previousStateFromQuery || ''
    const previousZip = previousZipFromState || previousZipFromQuery || ''
    const familyInvitationEmail = familyInvitationEmailFromState || familyInvitationEmailFromQuery || ''

    const isReadOnly = activationSource === 'AUTOENROLL' || !!residentId
    const isFirstNameReadOnly = isReadOnly && (!!firstNameFromActivation || !!firstNameFromResident)
    const isLastNameReadOnly = isReadOnly && (!!lastNameFromActivation || !!lastNameFromResident)
    const isCurrentStreetReadOnly = isReadOnly && (!!currentStreetFromActivation || !!streetFromResident)
    const isCurrentCityReadOnly = isReadOnly && (!!currentCityFromcAtivation || !!cityFromResident)
    const isCurrentStateReadOnly = isReadOnly && (!!currentStateFromActivation || !!stateFromResident)
    const isCurrentZipReadOnly = isReadOnly && (!!currentZipFromActivation || !!zipFromResident)

    /**
     * This is a hack but we don't have time to struggle.
     * We have to delay these calls until after the component has gone through its own series of renders
     * otherwise we end up with unreliable input validation.
     */
    const timeout = setTimeout(() => {
      setValues(
        {
          firstName,
          middleInitial,
          lastName,
          email,
          currentStreet,
          currentCity,
          currentState,
          currentZip,
          previousStreet,
          previousCity,
          previousState,
          previousZip,
          password: '',
          isCurrentAddress: !(previousStreet || previousCity || previousState || previousZip),
          familyInvitationEmail,
          isTermsAgree: false,
          isFirstNameReadOnly,
          isLastNameReadOnly,
          isCurrentStreetReadOnly,
          isCurrentCityReadOnly,
          isCurrentStateReadOnly,
          isCurrentZipReadOnly,
        },
        true,
      )

      setTouched(
        {
          firstName: !!firstName && !isFirstNameReadOnly,
          lastName: !!lastName && !isLastNameReadOnly,
          email: !!email,
          currentStreet: !!currentStreet && !isCurrentStreetReadOnly,
          currentCity: !!currentCity && !isCurrentCityReadOnly,
          currentState: !!currentState && !isCurrentStateReadOnly,
          currentZip: !!currentZip && !isCurrentZipReadOnly,
          previousStreet: !!previousStreet,
          previousCity: !!previousCity,
          previousState: !!previousState,
          previousZip: !!previousZip,
          familyInvitationEmail: !!familyInvitationEmail,
        },
        true,
      )
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [])
}

export default useGlobalAccountState
