import React, { FunctionComponent } from 'react'
import { Theme } from '@/theme'
import Dialog from '@/components/Dialog'
import { H2 } from '@/components/Headings'
import IconProgress from './IconProgress'
import IconSuccess from './IconSuccess'
import { Box, useTheme } from '@mui/material'

const useStyles = (theme: Theme) => ({
  root: {
    overflow: 'hidden',
  },
  paper: {
    textAlign: 'center',
    padding: '70px 0 90px',
    backgroundColor: '#FFF',
    margin: '24px',
    borderRadius: '12px',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(12),
    },
  },
  progress: {
    marginBottom: theme.spacing(3),
  },
  heading: {
    fontWeight: 800,
    letterSpacing: '1px',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase',
  },
})

const StatusMap = {
  progress: IconProgress,
  success: IconSuccess,
}

type Props = {
  status: 'progress' | 'success'
  title: string
  description: string
}

const LoadingIndicator: FunctionComponent<Props> = ({ status, title, description }: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const Status = StatusMap[status]

  return (
    <Dialog open={true} sx={{ paper: classes.paper }}>
      <Status variant={'modal'} />
      <H2 sx={classes.heading}>{title}</H2>
      <Box component={'p'}>{description}</Box>
    </Dialog>
  )
}

export default LoadingIndicator
