import React, { FunctionComponent } from 'react'
import { Box, keyframes } from '@mui/material'
import { stepperColor } from '@/theme'

const jumpy = keyframes`
  0% { transform: translateY(0) }
  50% { transform: translateY(-12px) }
  100% { transform: translateY(0) }
`

const useStyles = (props: any) => ({
  dotsContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: props.margin,
  },
  dots: {
    display: 'inline-block',
    width: props.width,
    height: props.height,
    background: props.background,
    borderRadius: '50%',
    marginRight: '4px',
  },
  dot1: {
    animation: `${jumpy} 1s linear infinite`,
  },
  dot2: {
    animation: `${jumpy} 1s .3s linear infinite`,
  },
  dot3: {
    animation: `${jumpy} 1s .6s linear infinite`,
  },
})

interface StyleProp {
  margin: string
  background: string
  width: string
  height: string
}

const StyleProps: Record<string, StyleProp> = {
  modal: {
    margin: '0 0 45px 0',
    background: stepperColor,
    width: '15px',
    height: '15px',
  },
  quickview: {
    margin: '26px 0 12px 0',
    background: '#FFF',
    width: '10px',
    height: '10px',
  },
}
interface Props {
  variant: 'modal' | 'quickview'
}

const IconProgress: FunctionComponent<Props> = ({ variant }: Props) => {
  const classes = useStyles(StyleProps[variant])

  return (
    <Box sx={classes.dotsContainer}>
      <Box sx={[classes.dots, classes.dot1]}></Box>
      <Box sx={[classes.dots, classes.dot2]}></Box>
      <Box sx={[classes.dots, classes.dot3]}></Box>
    </Box>
  )
}

export default IconProgress
