import React, { FunctionComponent } from 'react'
import { Theme, fontSecondary, uncheckedBoxBorderColor } from '@/theme'
import { DefaultPhoneNumber, PARTNERS_URL, PRIVACYPOLICY_URL, TCPA_URL, TERMS_URL, ValidationErrors } from '@/constants'
import { Box, Button, Checkbox, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'

const useStyles = (theme: Theme) => ({
  disclaimerText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: 'normal',
    margin: '0',
    color: theme.palette.common.black,
    padding: '0 0 10px 0',
    '&:first-of-type': {
      marginTop: '10px',
    },
  },
  disclaimerLinks: {
    minWidth: 'auto',
    background: 'none !important',
    verticalAlign: 'baseline',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: 'normal',
    color: theme.palette.primary.main,
    textTransform: 'none',
    textDecoration: 'underline',
    padding: '0',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  termsContainer: {
    display: 'flex',
    alignItems: 'start',
    marginLeft: '-11px',
  },
  termsError: {
    color: theme.palette.error.main,
    fontWeight: 600,
    fontSize: '12px',
    fontFamily: fontSecondary,
  },
  uncheckedBox: {
    borderRadius: '5px',
    height: '25px',
    width: '25px',
    border: uncheckedBoxBorderColor,
  },
  checkboxError: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
})

type Props = {
  isShowError?: boolean
  value: boolean
  onChange: ((event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void) | undefined
}

const Terms: FunctionComponent<Props> = ({ isShowError = false, value, onChange }: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <Box sx={classes.termsContainer}>
      <Checkbox
        sx={isShowError ? classes.checkboxError : undefined}
        color="primary"
        id="isTermsAgree"
        name="isTermsAgree"
        checked={value}
        onChange={onChange}
      />
      <Grid>
        <Box component={'p'} sx={classes.disclaimerText}>
          By checking this box and clicking ‘next’ you agree to be bound by the{' '}
          <Button
            onClick={() => window.open(TERMS_URL, 'Terms of Service Agreement', 'resizable')}
            sx={classes.disclaimerLinks}
          >
            Terms of Service Agreement,
          </Button>{' '}
          <Button
            onClick={() => window.open(PRIVACYPOLICY_URL, 'Privacy Policy', 'resizable')}
            sx={classes.disclaimerLinks}
          >
            Privacy Policy,
          </Button>{' '}
          and to receive important notices and{' '}
          <Button onClick={() => window.open(TCPA_URL, 'TCPA', 'resizable')} sx={classes.disclaimerLinks}>
            other communications electronically.
          </Button>
        </Box>
        <Box component={'p'} sx={classes.disclaimerText}>
          You are providing express written consent for IDIQ, parties calling on behalf of IDIQ, network{' '}
          <Button
            onClick={() => window.open(PARTNERS_URL, 'Network Partners', 'resizable')}
            sx={classes.disclaimerLinks}
          >
            partners
          </Button>
          , or any authorized third party on their behalf to e-mail, or to call or text you (including through automated
          means, e.g., through an automatic telephone dialing system or through the use of pre-recorded or artificial
          voice messages), to any telephone number you provide, even if your telephone number is listed on any internal,
          corporate, state, federal, or national Do-Not-Call (DNC) list, for any purpose, including marketing. Texts
          include SMS and MMS - charges may apply. This consent to such communications is not required as a condition to
          obtain any goods or services, and you may choose to speak with an individual customer service representative
          by contacting {DefaultPhoneNumber}.
        </Box>
        {isShowError && <Box sx={classes.termsError}>{ValidationErrors.TermsRequired}</Box>}
      </Grid>
    </Box>
  )
}

export default Terms
