import { Theme } from '@mui/material'
import {
  errorBorderColor,
  errorCardBackgroundColor,
  errorColor,
  fontSecondary,
  inputBorderColor,
  labelLineHeight,
  radioButtonOptionsLineHeight,
  uncheckedBoxBorderColor,
} from './../../theme'
import { cardBoxShadow } from '@/theme'

export const useStyles = (theme: Theme) => ({
  root: {
    margin: '-10px 0 7px 0',
    borderRadius: '15px',
    border: 'none',
    boxShadow: cardBoxShadow,
    background: 'none',
    '& .MuiFormControl-marginNormal': {
      marginTop: '8px',
      width: '100%',
    },
    '& .MuiButton-root': {
      padding: 0,
      margin: '0 1px 1px 1px',
    },
    '& .MuiFormHelperText-contained': {
      lineHeight: radioButtonOptionsLineHeight,
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '-60px',
    },
  },
  title: {
    fontFamily: theme.typography.h2.fontFamily || fontSecondary,
    textTransform: 'uppercase',
    margin: '0 0 4px 0',
  },
  subTitle: {
    marginTop: '24px',
  },
  groupedFields: {
    display: 'flex',
  },
  groupedSkeletonFields: {
    display: 'flex',
    gap: 20,
    margin: '20px 0',
  },
  groupedFieldsDesktop: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  firstName: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  middleInitial: {
    width: 96,
  },
  lastName: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
  },
  email: {
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1,
    },
  },
  emailContainer: {
    marginBottom: '28px',
  },
  previousAddress: {
    margin: '28px 0 12px 0',
  },
  password: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1,
    },
  },
  street: {
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1,
    },
  },
  city: {
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: 1,
    },
  },
  state: {
    width: '100%',
  },
  zipCode: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
    flex: 1,
    float: 'right',
  },
  checkbox: {
    marginTop: '20px',
    marginBottom: '4px',
  },
  checkboxFormControlLabel: {
    marginRight: 0,
    marginLeft: '-11px !important',
  },
  checkboxFormControlLabelText: {
    lineHeight: labelLineHeight,
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.common.black,
  },
  stateFormControl: {
    width: '100%',
  },
  validInput: {
    '& .MuiOutlinedInput-notchedOutline, & .MuiSelect-outlined': {
      borderColor: `${theme.palette.secondary.main} !important`,
    },
  },
  eyeIcon: {
    color: 'inherit',
    '& .MuiSvgIcon-root': {
      width: 24,
      height: 24,
    },
  },
  select: {
    '& fieldset': {
      borderColor: inputBorderColor,
    },
  },
  stateAdornment: {
    marginRight: '22px',
  },
  notchedOutline: {
    borderColor: inputBorderColor,
  },
  uncheckedBox: {
    borderRadius: '5px',
    height: '25px',
    width: '25px',
    border: uncheckedBoxBorderColor,
  },
  errorCard: {
    border: errorBorderColor,
    backgroundColor: errorCardBackgroundColor,
    borderRadius: '8px',
    margin: '24px 0',
    padding: '24px',
    color: errorColor,
  },
  familyInvitationEmail: {
    width: '50%',
    paddingRight: 'calc(15px / 2)',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
    },
  },
  familyTitle: {
    marginBottom: 0,
    marginTop: 0,
    marginRight: '15px',
  },
  familyPlanTitleWrapper: {
    marginBottom: '5px',
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
  },
})
