import React, { FunctionComponent } from 'react'
import { Box, keyframes, SxProps, Theme } from '@mui/material'
import { PlayArrow } from '@mui/icons-material'

type Props = {
  sx?: SxProps<Theme>
}

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4) }
  100% { box-shadow: 0 0 0 16px rgba(255, 255, 255, 0) }
`

const useStyles = () => ({
  container: {
    width: '90px',
    height: '80px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#FFF',
  },
  iconWrapper: {
    animation: `${pulse} 2s infinite`,
    width: '44px',
    height: '44px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '6px',
    position: 'relative',
    overflow: 'hidden',

    '&:before': {
      content: '""',
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      background: 'rgba(255, 255, 255, 0.3)',
    },
  },
})

const AnimatedPlayButton: FunctionComponent<Props> = ({ sx }: Props) => {
  const classes = useStyles()

  return (
    <Box sx={{ ...classes.container, ...sx }}>
      <Box sx={classes.iconWrapper}>
        <PlayArrow fontSize={'medium'} />
      </Box>
    </Box>
  )
}

export default AnimatedPlayButton
