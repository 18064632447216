import { Box } from '@mui/material'
import React, { FunctionComponent } from 'react'

const useStyles = () => ({
  checkmarkContainer: {
    animation: `$fadeIn .3s ease-in forwards`,
    marginBottom: '10px',
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: '0',
      transform: 'scale(1.7)',
    },
    '100%': {
      opacity: '1',
      transform: 'scale(1.2)',
    },
  },
})

const IconSuccess: FunctionComponent = () => {
  const classes = useStyles()

  return (
    <Box sx={classes.checkmarkContainer}>
      <Box component={'img'} src="/images/icon_success_checkmark.svg" alt="checkmark" />
    </Box>
  )
}

export default IconSuccess
