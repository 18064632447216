import React, { FunctionComponent } from 'react'
import SubHeader from '@/components/SubHeader'
import ProgressStepper from '@/components/ProgressStepper'
import { EnrollmentStep } from '@/state/types'
import FormAccountInfoContainer from '@/containers/FormAccountInfoContainer'
import { EnrollmentFormID, ErrorCodes, LoadingIndicatorMessages, Routes } from '@/constants'
import LoadingIndicator from '@/components/LoadingIndicator'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectEnrollmentState,
  selectIndicativeEnrichmentState,
  selectPlanState,
  selectVerificationState,
  selectActivationState,
} from '@/state/selectors'
import { useRouter } from 'next/router'
import { setBypassSuccess } from '@/state/indicativeEnrichment'
import SubmitButton from '@/components/SubmitButton'
import { enrollMember } from '@/state/actions'
import theme from '@/theme'
import { isNoAccount } from '@/utils'
import dynamic from 'next/dynamic'
import { Box, useMediaQuery } from '@mui/material'

const FormIdentityInfoContainer = dynamic(() => import('@/containers/FormIdentityInfoContainer'))
const FormBillingInfoContainer = dynamic(() => import('@/containers/FormBillingInfoContainer'))
const FormPrefillInfoContainer = dynamic(() => import('@/containers/FormPrefillInfoContainer'))

const EnrollmentBody: FunctionComponent = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const { questions } = useSelector(selectVerificationState)
  const { step, isFetching, enrolledMember, errorCode } = useSelector(selectEnrollmentState)
  const { isFetchingIdentity, indicativeEnrichmentReport, bypassSuccessScreen } = useSelector(
    selectIndicativeEnrichmentState,
  )
  const { details } = useSelector(selectPlanState)
  const { isProveWorkflow } = useSelector(selectEnrollmentState)
  const { details: activationDetails } = useSelector(selectActivationState)
  const isNoAccountPlan = isNoAccount(details)

  const isConfirmationPanelRendering =
    errorCode === ErrorCodes.EnrollmentPending ||
    errorCode === ErrorCodes.GamerLogic ||
    errorCode === ErrorCodes.IncludePrepaidRedirectOn

  const handleReinstateSubmit = () => {
    if (isConfirmationPanelRendering) {
      dispatch(enrollMember())
    }
  }

  if (isFetching) {
    if (activationDetails) {
      return (
        <LoadingIndicator
          status="progress"
          title={LoadingIndicatorMessages.activationProcessing.title}
          description={LoadingIndicatorMessages.activationProcessing.description}
        />
      )
    }

    if (isNoAccountPlan) {
      return (
        <LoadingIndicator
          status="progress"
          title={LoadingIndicatorMessages.noAccountOrderProcessing.title}
          description={LoadingIndicatorMessages.noAccountOrderProcessing.description}
        />
      )
    }

    return (
      <LoadingIndicator
        status="progress"
        title={LoadingIndicatorMessages.paymentProcessing.title}
        description={LoadingIndicatorMessages.paymentProcessing.description}
      />
    )
  }

  if (enrolledMember) {
    if (questions && questions.length) {
      router.push(Routes.Verify, Routes.Verify)

      return (
        <LoadingIndicator
          status="progress"
          title={LoadingIndicatorMessages.retrievingQuestions.title}
          description={LoadingIndicatorMessages.retrievingQuestions.description}
        />
      )
    } else {
      if (activationDetails) {
        return (
          <LoadingIndicator
            status="progress"
            title={LoadingIndicatorMessages.activationCompleted.title}
            description={LoadingIndicatorMessages.activationCompleted.description}
          />
        )
      }

      if (isNoAccountPlan) {
        return (
          <LoadingIndicator
            status="progress"
            title={LoadingIndicatorMessages.noAccountOrderConfirmed.title}
            description={LoadingIndicatorMessages.noAccountOrderConfirmed.description}
          />
        )
      }

      return (
        <LoadingIndicator
          status="success"
          title={LoadingIndicatorMessages.paymentConfirmed.title}
          description={LoadingIndicatorMessages.paymentConfirmed.description}
        />
      )
    }
  }

  if (isFetchingIdentity) {
    return (
      <LoadingIndicator
        status="progress"
        title={LoadingIndicatorMessages.confirmingIdentity.title}
        description={LoadingIndicatorMessages.confirmingIdentity.description}
      />
    )
  }

  if (indicativeEnrichmentReport && !bypassSuccessScreen) {
    new Promise((resolve) => setTimeout(resolve, 2000)).then(() =>
      dispatch(setBypassSuccess({ bypassSuccessScreen: true })),
    )

    return (
      <LoadingIndicator
        status="success"
        title={LoadingIndicatorMessages.identityConfirmed.title}
        description={LoadingIndicatorMessages.identityConfirmed.description}
      />
    )
  }

  let component = <FormAccountInfoContainer />

  if (!details || details?.isPlanAuthRequired || isProveWorkflow) {
    switch (step) {
      case EnrollmentStep.AccountInfo:
        component = <FormAccountInfoContainer />
        break
      case EnrollmentStep.IdentityInfo:
        component = isProveWorkflow ? <FormPrefillInfoContainer /> : <FormIdentityInfoContainer />
        break
      case EnrollmentStep.BillingInfo:
        component = <FormBillingInfoContainer />
    }
  } else {
    if (step === EnrollmentStep.AccountInfo || activationDetails) {
      component = <FormAccountInfoContainer />
    } else {
      component = <FormBillingInfoContainer />
    }
  }

  return (
    <>
      <SubHeader>
        <ProgressStepper activeStep={step} />
      </SubHeader>
      <Box sx={{ maxWidth: '1200px', m: '0 auto' }}>
        {component}
        {!isDesktop && step === EnrollmentStep.BillingInfo && (
          <Box sx={{ mt: 7 }}>
            <SubmitButton form={EnrollmentFormID} onClick={handleReinstateSubmit} />
          </Box>
        )}
      </Box>
    </>
  )
}

export default EnrollmentBody
