import React, { FunctionComponent, SyntheticEvent } from 'react'
import { copyFontSize, Theme } from '@/theme'
import AnimatedPlayButton from '../AnimatedPlayButton'
import { useRouter } from 'next/router'
import { ErrorCodes, Routes, TUErrorResponseCodes } from '@/constants'
import { submitButtonBoxShadow } from '@/theme'
import { useSelector } from 'react-redux'
import { selectEnrollmentState, selectPlanState } from '@/state/selectors'
import { EnrollmentStep } from '@/state/types'
import { isNoAccount } from '@/utils'
import { Box, Button, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid2'

const useStyles = (theme: Theme) => ({
  submitButton: {
    height: '60px',
    margin: '3px 31px 0 31px',
    width: '338px',
    maxWidth: '100%',
    boxShadow: submitButtonBoxShadow,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      margin: '3px 0 0 0',
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '24px',
    },
  },
  submitButtonIcon: {
    position: 'absolute',
    right: '0',
    marginRight: '-10px',
    maxWidth: '80px',
    marginTop: '0px',
    display: 'flex',
  },
  submitButtonWrap: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  submitText: {
    fontSize: copyFontSize,
    fontWeight: 'bold',
    letterSpacing: '2px',
    color: theme.palette.common.white,
    textTransform: 'uppercase',
  },
  validDesc: {
    marginBottom: '0',
    color: theme.palette.common.black,
  },
  smallVariant: {
    '& .MuiGrid2-root': {
      alignItems: 'flex-start',
    },
    '& .MuiButton-root': {
      width: 'auto',
      height: '40px',
      borderRadius: '20px',
      padding: '7px 30px',
      boxShadow: 'none',
      margin: '20px 0 0 0',
    },
    '& p': {
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: 'normal',
      textTransform: 'none',
    },
  },
})

interface Props {
  form?: string
  onClick?: () => void
  disabled?: boolean
  text?: string
  hideIcon?: boolean
  isSmall?: boolean
}

const SubmitButton: FunctionComponent<Props> = ({ form, onClick, disabled, text, hideIcon, isSmall }: Props) => {
  const theme = useTheme()
  const classes = useStyles(theme)
  const router = useRouter()
  const { errorCode, error, step } = useSelector(selectEnrollmentState)
  const { details } = useSelector(selectPlanState)
  const id = router.pathname === Routes.Enroll ? 'account-next-btn' : `${router.pathname.substring(1)}-next-btn`
  const isLastStep =
    (isNoAccount(details) && step === EnrollmentStep.IdentityInfo) || step === EnrollmentStep.BillingInfo
  text = text || (isLastStep && 'Submit') || 'Next'

  disabled =
    disabled ||
    errorCode === ErrorCodes.DuplicateMemberBySSN ||
    errorCode === ErrorCodes.DuplicateMemberByHousehold ||
    TUErrorResponseCodes.has(errorCode || '') ||
    error?.toLocaleLowerCase().trim() === 'duplicate member by account'

  return (
    <Grid container sx={isSmall ? classes.smallVariant : undefined}>
      <Grid size={12} sx={classes.submitButtonWrap}>
        <Button
          type="submit"
          variant="contained"
          form={form}
          id={id}
          sx={classes.submitButton}
          disabled={disabled}
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation()
            onClick && onClick()
          }}
        >
          <Box component={'p'} sx={classes.submitText}>
            {text}
          </Box>
          {!hideIcon && <AnimatedPlayButton sx={classes.submitButtonIcon}></AnimatedPlayButton>}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SubmitButton
