import React, { FC } from 'react'
import { Box, FormControlLabel, Typography, FormControlLabelProps } from '@mui/material'

const useStyles = () => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  control: {
    marginRight: '0',
  },
})

const FormControlExtLabel: FC<FormControlLabelProps> = ({ label, sx, ...rest }) => {
  const localClasses = useStyles()

  return (
    <Box sx={localClasses.wrapper}>
      <FormControlLabel label={null} sx={localClasses.control} {...rest} />
      {label && <Typography sx={sx}>{label}</Typography>}
    </Box>
  )
}

export default FormControlExtLabel
