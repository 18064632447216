import React, { FunctionComponent } from 'react'
import { Headings } from '@/constants'
import { useStyles } from './styles'
import { H2 } from '../Headings'
import { Box, Card, Skeleton, useTheme } from '@mui/material'

const AccountInfoSkeleton: FunctionComponent = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <>
      <Card sx={classes.root} variant="outlined">
        <H2 sx={classes.title}>{Headings.AccountInformation}</H2>
        <Box sx={classes.groupedSkeletonFields}>
          <Skeleton sx={classes.firstName} variant="rectangular" height={60} />
          <Skeleton sx={classes.lastName} variant="rectangular" height={60} />
        </Box>
        <Box sx={classes.groupedSkeletonFields}>
          <Skeleton sx={classes.firstName} variant="rectangular" height={60} />
          <Skeleton sx={classes.lastName} variant="rectangular" height={60} />
        </Box>
        <H2 sx={classes.title}>{Headings.CurrentAddress}</H2>
        <Box sx={classes.groupedSkeletonFields}>
          <Skeleton sx={classes.firstName} variant="rectangular" height={60} />
          <Skeleton sx={classes.lastName} variant="rectangular" height={60} />
        </Box>
        <Box sx={classes.groupedSkeletonFields}>
          <Skeleton sx={classes.firstName} variant="rectangular" height={60} />
          <Skeleton sx={classes.lastName} variant="rectangular" height={60} />
        </Box>
        <Skeleton variant="text" />
      </Card>
    </>
  )
}

export default AccountInfoSkeleton
